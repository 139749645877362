declare var gantt;

gantt.config.tooltip_timeout = 30;
gantt.config.tooltip_offset_y = 20;
gantt.config.tooltip_offset_x = 10;
gantt.config.tooltip_hide_timeout = 30;

import {TooltipManager} from "./tooltipManager";

const tooltipManager = new TooltipManager();

gantt.ext.tooltips = tooltipManager;

gantt.attachEvent("onGanttReady", function(){

	tooltipManager.tooltipFor({
		selector: "["+gantt.config.task_attribute+"]:not(.gantt_task_row)",
		html: (event: MouseEvent) => {
			if (gantt.config.touch && !gantt.config.touch_tooltip) {
				return;
			}

			const targetTaskId = gantt.locate(event);
			if(gantt.isTaskExists(targetTaskId)){
				const task = gantt.getTask(targetTaskId);
				return gantt.templates.tooltip_text(task.start_date, task.end_date, task);
			}
			return null;
		},
		global: false
	});
});

gantt.attachEvent("onDestroy", function() {
	tooltipManager.destructor();
});
