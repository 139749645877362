import * as domEventsScope from "../../utils/dom_event_scope";
import * as domHelpers from "../../utils/dom_helpers";
import * as helpers from "../../utils/helpers";
import { Tooltip } from "./tooltip";

interface ITrackerTarget {
	selector: string;
	onmouseenter: (event: MouseEvent, node: HTMLElement) => void;
	onmousemove: (event: MouseEvent, node: HTMLElement) => void;
	onmouseleave: (event: MouseEvent, node: HTMLElement) => void;
	global: boolean;
}

interface ITooltipConfig {
	selector: string;
	html: (event: MouseEvent, node: HTMLElement) => string;
	global: boolean;
}

declare var gantt:any;

export class TooltipManager{
	tooltip: Tooltip = new Tooltip();
	protected _domEvents: any;
	private _listeners: object = {};

	constructor() {
		this._domEvents = domEventsScope();
	}
	destructor(): void{
		this.tooltip.hide();
		this._domEvents.detachAll();
	}
	attach(config: ITrackerTarget): void {
		let root = document.body;
		if(!config.global){
			root = gantt.$root;
		}

		let watchableTarget = null;
		const handler = (event) => {
			const eventTarget = domHelpers.getTargetNode(event);
			const targetNode = domHelpers.closest(eventTarget, config.selector);
			if(domHelpers.isChildOf(eventTarget, this.tooltip.getNode())){
				return;
			}

			if(watchableTarget){
				if(targetNode){
					config.onmousemove(event, targetNode);
				}else{
					config.onmouseleave(event, watchableTarget);
					watchableTarget = null;
				}
			}else{
				if(targetNode){
					watchableTarget = targetNode;
					config.onmouseenter(event, targetNode);
				}
			}
		};

		this.detach(config.selector);
		this._domEvents.attach(root, "mousemove", handler);
		this._listeners[config.selector] = {
			node: root,
			handler
		};
	}

	detach(selector: string): void {
		const listener = this._listeners[selector];
		if(listener){
			this._domEvents.detach(listener.node, "mousemove", listener.handler);
		}
	}

	tooltipFor(config: ITooltipConfig): void {
		const cloneDomEvent = (event: MouseEvent) => {
			let clone = event;
			// making events survive timeout in ie
			// tslint:disable-next-line no-string-literal
			if(document["createEventObject"] && !document.createEvent){
				// tslint:disable-next-line no-string-literal
				clone = document["createEventObject"](event);
			}
			return clone;
		};

		const delayShow = helpers.delay((event: MouseEvent, html: string) => {
			this.tooltip.setContent(html);
			this.tooltip.show(event);
		}, gantt.config.tooltip_timeout || 1);

		const delayHide = helpers.delay(() => {
			delayShow.$cancelTimeout();
			this.tooltip.hide();
		}, gantt.config.tooltip_hide_timeout || 1);

		this.attach({
			selector: config.selector,
			global: config.global,
			onmouseenter:(event: MouseEvent, node: HTMLElement) => {
				const html = config.html(event, node);
				if(html){
					delayShow(cloneDomEvent(event), html);
				}
			},
			onmousemove:(event: MouseEvent, node: HTMLElement) => {
				const html = config.html(event, node);
				if(html){
					delayShow(cloneDomEvent(event), html);
				}else{
					delayShow.$cancelTimeout();
					delayHide();
				}
			},
			onmouseleave:() => {
				delayShow.$cancelTimeout();
				delayHide();
			},
		});
	}
}